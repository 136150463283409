<template>
  <transition name="modal-fade" @after-leave="close">
    <div v-if="isShow" class="error-toast">
      <div class="notice-icon">!</div>
      <button class="close-button" @click.stop="close">×</button>
      <div class="text">
        {{ text }}
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isShow: false,
    };
  },
  methods: {
    toggle() {
      this.isShow = !this.isShow;
    },
    close() {
      this.isShow = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.error-toast {
  position: relative;
  width: 100%;
  max-width: rem(768);
  min-height: rem(100);
  padding: rem(20);

  color: #ffffff;
  background: rgba(#ff6262, 0.95);
  box-shadow: 0 2px 6px rgba(#000, 0.16);

  display: flex;
  align-items: center;

  .notice-icon {
    position: absolute;
    left: rem(20);
    top: rem(20);
    width: rem(24);
    height: rem(24);

    border-radius: 50%;
    color: #ff6262;
    letter-spacing: normal;
    background: #ffffff;

    display: flex;
    align-items: center;
    justify-content: center;
  }

  .close-button {
    position: absolute;
    top: rem(15);
    right: rem(15);

    width: rem(20);
    height: rem(20);
    padding: 0;

    border: none;
    border-radius: 2px;
    background: #de5050;
    color: #ffffff;
    font-size: rem(16);

    display: flex;
    align-items: center;
    justify-content: center;

    &:focus {
      outline: none;
    }
  }

  .text {
    margin-top: rem(34);
    font-size: rem(12);
  }
}
.modal-fade {
  &-enter-active,
  &-leave-active {
    transition: opacity 0.25s ease-out;
  }
  &-enter-from,
  &-leave-to {
    opacity: 0;
  }
}
</style>
